.loginPage {
  position: absolute;
  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
  background: #fff;
  padding-top: 10px;
  min-height: 320px;
  display: flex;
  align-items: center;
}

.loginPageContent {
  margin: 0 auto;
  width: 300px;
}

.loginPageContent img.logo {
  width: 100%;
  margin-bottom: 50px;
}

.loginPageContent input:hover,
.loginPageContent input:focus,
.loginPageContent .ant-input-password:hover input {
  outline: none;
  box-shadow: none;
  border-color: var(--c-corporate) !important;
}

.loginPageContent input:-webkit-autofill,
.loginPageContent input:-webkit-autofill:hover,
.loginPageContent input:-webkit-autofill:focus,
.loginPageContent input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.loginPageContent button {
  width: 100%;
  margin-top: 20px;
  border: 1px transparent solid;
  box-shadow: none;
  color: var(--c-corporate);
  transition: all 0.25s ease-in-out;
}

.loginPageContent button:hover,
.loginPageContent button:focus {
  border: 1px var(--c-corporate) solid;
  color: var(--c-corporate);
}

.loginPageContent p.desc {
  color: #aaa;
  text-align: center;
  margin-top: 15px;
  font-size: 0.9em;
}

.loginPageContent p.desc span {
  white-space: nowrap;
}

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

/* @media screen and (max-width: 992px) {
  .loginPageContent {
    margin-top: 60px;
  }
} */

.loginPageContent input {
  background: transparent;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  transition: all 0.25s ease-in-out;
}

@media screen and (max-width: 768px) {
  .loginPageContent img.logo {
    width: 70%;
    display: inline-block;
    margin: 30px auto 50px;
    display: block;
  }
}

@media (max-height: 550px) {
  .loginPage {
    padding-bottom: 30px;
  }

  .loginPage .footer,
  .loginPage .desc {
    display: none;
  }

  .loginPageContent {
    margin: 0 auto;
    margin-top: 25px;
    width: 300px;
  }

  .loginPageContent img.logo {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 30px;
    display: block;
  }
}
