.card {
  margin-bottom: 30px;
}

.commands {
  margin-left: 5px;
}

.commands button {
  margin-left: 5px;
}

.newsMore {
  text-align: center;
  margin: 30px 0;
}
