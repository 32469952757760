.widgetTitle {
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 30px;
}

.makerCollapse .ant-collapse-content-box {
  padding: 0;
  border: none;
}

.makerText {
  padding: 0 15px;
  width: calc(100% - 30px);
  height: 30px;
  padding-top: 6px;
  font-size: 14px;
  cursor: pointer;
}

.makerIcon {
  margin-right: 15px;
}
