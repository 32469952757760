body {
  font-family: Helvetica, sans-serif;
  color: #505458;
  background: var(--c-app-background);
  margin: 0;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

#page-container {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  position: relative;
  transition: all 0.25s ease-in-out;
}

#page-content {
  padding: 90px 20px 50px 20px;
  background: var(--c-app-background);
  margin-left: 220px;
  position: relative;
  width: calc(100% - 220px);
  transition: all 0.25s ease-in-out;
  height: auto;
  min-height: 100vh;
  overflow-x: scroll;
}

#page-content #updater {
  display: none;
}

/*SIDEBAR*/
#sidebar {
  position: fixed;
  top: 0;
  padding-top: 10px;
  z-index: 100;
  /*overflow: hidden;*/

  transition: all 0.25s ease-in-out;
  width: 220px;
  height: 100vh;
}

#sidebar-opener {
  display: none;
}

/*.sidebar:hover {
	width: 240px;
	opacity: 0.97;
}
*/
#sidebar .sidebar-menu-logo {
  margin: 15px 10px 30px 10px;
  padding-bottom: 9px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  /*width: 190px;*/
  /*border-bottom: 1px #ddd solid;*/
}

/*.sidebar:hover .sidebar-menu-logo {
	width: 190px;

}
*/
#sidebar .sidebar-menu-logo img {
  width: 100%;
}

#sidebar .menu {
  margin-top: 34px;
  overflow-y: scroll;
  max-height: calc(100% - 255px);
}

#sidebar .menu-item {
  display: block;
  padding: 15px 12px;
  height: 50px;
  position: relative;
  border-left: 2px solid transparent;
  color: #36404a;

  transition: all 0.25s ease-in-out;
}

#sidebar .menu-item i {
  font-size: 18px;
  position: relative;
  text-align: center;
  /*width: 21px;*/
}

#sidebar .menu-item .menu-item-text {
  position: absolute;
  top: 0;
  height: 100%;
  display: block;
  padding: 13px 0 17px;
  text-align: left;
  letter-spacing: 0.2px;
  font-size: 15px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.25s ease, left 0.25s ease-in-out;
  opacity: 1;
  left: 42px;
}

/*.sidebar:hover .menu-item .menu-item-text {
	opacity: 1;
	left: 60px;

}*/
#sidebar .menu-item.active {
  background: #fafafa;
  color: var(--c-corporate) !important;
}

#sidebar .submenu-container.isOpen .submenu-item {
  color: var(--c-corporate) !important;
}

#sidebar .menu-item:hover {
  background: #fafafa;
}

#sidebar .bottom-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#sidebar .submenu-container .submenu-anchor {
  position: absolute;
  top: 18px;
  right: 13px;
}

#sidebar .submenu-container .submenu {
  display: none;
}

#sidebar .submenu-container.isOpen .submenu {
  display: block;
  top: 0;
  width: 240px;
  opacity: 0.95;
  margin-left: 15px;
  width: calc(100% - 15px);
}

#sidebar .submenu-container.isOpen .submenu .menu-item {
  /*border-left: 2px #4f5e6d solid;*/
}

.submenu .menu-item span {
  /*left: 20px !important;*/
}

/*TITLE FIXED*/
.title-fixed {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 5px;
  height: 65px;
  padding: 15px 0;
  border-bottom: 1px #ddd solid;
  z-index: 999;
  text-align: left;
}

.title-fixed h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 2px;
  /*letter-spacing: 0.25px;*/
}

.title-fixed.with-back-btn h4 {
  cursor: pointer;
}

.title-fixed h4 i {
  font-size: 18px;
  margin-right: 3px;
}

.main-buttons {
  position: absolute;
  right: 0;
  top: 15px;
}

.loader-progress {
  text-align: center;
  padding: 15px 0;
  font-size: 35px;
}

.isl-link {
  width: 110px;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  bottom: 7px;
}

.isl-link * {
  color: #aaa !important;
}

.isl-link img {
  margin-top: -19px;
  margin-right: -8px;
  float: left;
}
.isl-link .isl-link-text {
  width: 180px;
  text-align: left;
}

.isl-link .action {
  font-size: 0.75em;
  display: block;
  margin-bottom: -5px;
  letter-spacing: -0.7px;
}

.isl-link .isldesign {
  margin-top: 0;
  padding-top: 0;
  letter-spacing: 0.3px;
}

footer {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 40px);
  text-align: center;
  color: #98a6ad;
  font-size: 12px;
  margin-bottom: 2px;
}

footer a {
  color: inherit;
}

@media screen and (max-width: 768px) {
  #page-content {
    padding: 75px 0 70px;
    /*background: #fff;*/
    position: absolute;
    margin-left: 0;
    width: 100%;
    min-height: 100vh;
  }

  .standalone #page-content {
    padding: 85px 0 60px;
    min-height: calc(100vh + 20px);
  }

  #page-content #updater {
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: -10px;
    font-size: 20px;
  }

  #sidebar {
    height: 0;
    min-height: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  /*SIDEBAR*/
  #sidebar .menu {
    height: 70px;
    padding-top: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transition: none;
    z-index: 1000;
    border-top: 1px #ccc solid;
    background: var(--c-app-background);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-self: center;
    justify-items: center;
    background: #fff;
    overflow: visible;
  }

  #sidebar .menu > .menu-item,
  #sidebar .menu > .submenu-container > .menu-item {
    padding: 8px 0;
    color: #707070;
    width: 100%;
    text-align: center;
  }

  #sidebar .menu-item.active,
  #sidebar .menu-item:hover {
    background: transparent;
  }

  #sidebar .menu > .menu-item > .menu-item-text,
  #sidebar .menu > .submenu-container > .menu-item > .menu-item-text {
    position: static;
    display: block;
    font-size: 8px;
    text-transform: uppercase;
    width: 100%;
    top: 32px;
    left: 0;
    text-align: center;
    padding: 0;
    height: 10px;
  }

  #sidebar .menu-item i {
    font-size: 20px;
  }

  #sidebar .sidebar-menu-logo {
    display: none;
  }

  #sidebar .sidebar-menu-logo img {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  #sidebar .bottom-menu {
    display: none;
  }

  #sidebar .submenu-container .submenu,
  #sidebar .submenu-container.isOpen .submenu {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    top: auto;
    margin-left: 0;
    background: #fff;
    z-index: 1000;
    opacity: 1;
  }

  #sidebar .submenu-container .submenu-layer {
    position: fixed;
    top: auto;
    bottom: 70px;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    overflow: hidden;

    background: transparent;
    height: 0;

    transition: all 250ms ease-in-out;
  }

  #sidebar .submenu-container.isOpen .submenu-layer {
    height: calc(100% - 50px);
    background: rgba(51, 51, 51, 0.5);
  }

  #sidebar .submenu-container .submenu .menu-item,
  #sidebar .submenu-container.isOpen .submenu .menu-item {
    text-align: left;
    border-top: 1px #d9d9d9 solid;
    border-left: none;
    padding-left: 15px;
  }

  #sidebar .submenu-container .submenu .menu-item .menu-item-text,
  #sidebar .submenu-container.isOpen .submenu .menu-item .menu-item-text {
    padding-left: 30px;
    padding-top: 15px;
  }

  .title-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 55px;
    padding: 0 10px;
    background: var(--c-corporate);
    text-align: center;
    border: none;
  }

  .title-fixed.with-back-btn {
    text-align: left;
  }

  .standalone.ios.portrait .title-fixed {
    height: 70px;
    padding-top: 20px;
  }

  .title-fixed h4 {
    color: #fff;
    margin-top: 4px;
    padding: 10px 0;
  }

  .main-buttons {
    right: 10px;
    top: 0;
    height: 55px;
  }

  .standalone.ios.landscape .main-buttons {
    top: 5px;
  }

  .standalone.ios.portrait .main-buttons {
    top: 33px;
  }

  .main-buttons button {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
    padding: 1px 10px !important;
    font-size: 19px !important;
    padding: 10px 12px !important;
    height: 55px !important;
  }
  .standalone.ios.portrait .main-buttons button {
    padding: 0 12px !important;
    height: 35px !important;
  }

  .main-buttons button:after {
    display: none !important;
  }

  .main-buttons button.ant-btn-loading,
  .standalone.ios.portrait .main-buttons button.ant-btn-loading {
    padding-left: 26px !important;
  }

  .standalone .main-buttons button {
    height: 45px !important;
  }

  .main-buttons button span {
    display: none;
  }

  .main-buttons button i {
  }

  footer {
    display: none;
  }
}
