.form-item {
  margin-bottom: 15px;
}

.control-label {
  display: block;
  margin-top: 5px;
}

.formik-input.hasError * {
  border-color: var(--c-danger);
}

.quill {
  background: #fff;
}

.ql-editor {
  min-height: 250px;
  position: relative;
}

.ant-notification-topRight {
  top: 50px !important;
  margin-right: 20px;
}

.ant-notification-topRight .ant-notification-notice {
  margin-bottom: 0 !important;
  margin-top: 5px;
}

.ql-tooltip.ql-editing {
  left: 15px !important;
}

.form-item {
  grid-template-columns: 100%;
  grid-row-gap: 2px;
  margin-bottom: 10px;
}

.form-item .control-label {
  padding-left: 12px;
  font-size: 12px;
  text-transform: uppercase;
  justify-self: left;
}

.form-item .ant-checkbox-wrapper {
  margin-top: 4px !important;
}

.html-container img {
  max-width: 100%;
}

.big-table-filter svg {
  margin-top: -9px !important;
  margin-left: -9px !important;
}

.ant-calendar-picker,
.ant-input-number {
  width: 100%;
}

.ant-table-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.ant-table-small .ant-table-body {
  margin: 0 !important;
}

.table-clickable-rows tbody tr {
  cursor: pointer;
}

.grid-form-item {
  display: grid;
  margin-bottom: 20px;
  grid-gap: 20px;
  max-width: 400px;
  align-items: center;
}

.grid-form-item label {
  /* justify-self: right; */
}

.report-filter {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  width: calc(33% - 15px);
}

.report-filter label {
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .report-filter {
    display: grid;
    margin: 0 15px;
    margin-bottom: 15px;
    width: auto;
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }

  .report-filter label {
    font-size: 14px;
    text-align: right;
    margin-bottom: 0;
    margin-right: 15px;
  }

  .mobile-fullwidth-button {
    text-align: center;
    width: 100%;
    background: #fff;
    color: var(--c-positive);
    border: none;
    height: 38px;
    padding: 8px 0;
    border-radius: 0;
  }

  .form-item input,
  form .ant-select-selection--single,
  .ant-input-number {
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding-top: 5px;
    height: 40px;
  }

  .ant-input-number-input-wrap input {
    padding-top: 0;
    height: 30px;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ant-table-small {
    border-radius: 0;
    border: none;
  }
}
