@media screen and (max-width: 768px) {
  .ant-card {
    border: none !important;
  }

  .cameras {
    border: none;
  }

  .hidden-mobile {
    display: none !important;
  }

  .ant-calendar-input-wrap,
  .ant-calendar-input,
  .ant-calendar-next-year-btn,
  .ant-calendar-prev-year-btn {
    display: none !important;
  }

  .ant-calendar-header .ant-calendar-next-month-btn {
    right: 5px;
    padding: 0 10px;
  }

  .ant-calendar-header .ant-calendar-prev-month-btn {
    left: 5px;
    padding: 0 10px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 14px 14px;
  }
}

@media screen and (min-width: 769px) {
  .hidden-web {
    display: none !important;
  }
}
