.easy-table {
  width: 100%;
}

.easy-table th {
  text-align: center;
  font-size: 12px;
}

.table-emu-container {
  display: grid;
  grid-gap: 3px;
}

.table-emu-header {
  text-align: center;
  font-weight: 600;
  font-size: 0.9em;
}

.table-emu-header div {
  padding: 6px 0;
}

.table-emu-container .no-border {
  border: none;
  box-shadow: none;
}

.easy-array-delete-container {
  margin-top: 20px;
}
