:root {
  --c-positive: #99c21c;
  --c-danger: #da5c66;
  --c-app-background: #ebeff2;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
}

.text-center {
  text-align: center;
}

#root {
}

@media screen and (max-width: 992px) {
  .btn span {
    display: none;
  }

  .btn i {
    margin-right: 0 !important;
  }
}

.posts-modal {
  width: 70%;
  max-height: calc(85% - 70px);
  overflow-y: scroll;
  top: 70px;
  pointer-events: auto;
  padding-bottom: 0;
}

.posts-modal .ant-modal-body {
  padding: 0;
}
